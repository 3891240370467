// extracted by mini-css-extract-plugin
export var cardImage = "UnitsPage-module--card-image--KtIat";
export var cardMobile = "UnitsPage-module--card-mobile--ukH+C";
export var cardWrapper = "UnitsPage-module--card-wrapper--1kAee";
export var containerSlider = "UnitsPage-module--container-slider--0a0Xm";
export var content1364 = "UnitsPage-module--content-1364--vhmW3";
export var controls = "UnitsPage-module--controls--my680";
export var core = "UnitsPage-module--core--svY9P";
export var disabled = "UnitsPage-module--disabled--B7LSz";
export var displayNone = "UnitsPage-module--display-none--U1SGg";
export var flags = "UnitsPage-module--flags--ul0Zu";
export var flex = "UnitsPage-module--flex--O64qy";
export var formYellow = "UnitsPage-module--form-yellow--aWRpe";
export var phones = "UnitsPage-module--phones--Ll63h";
export var restaurant = "UnitsPage-module--restaurant--43Xqr";
export var slickContainer = "UnitsPage-module--slick-container--uEcxO";
export var sliderText = "UnitsPage-module--slider-text--AeesO";