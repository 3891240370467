import React, { useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { Router } from '@reach/router'
import Slider from 'react-slick'
import { PageProps, Link } from 'gatsby'
import { useApi } from '~/api'
import {
  Head,
  Nav,
  ButtonRoutes,
  Form,
  ReactLightBox,
  Footer,
} from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/UnitsPage.module.styl'
import whats from '~/assets/svg/svg-whats.svg'

type PageContext = {
  id: number
  image: string
  slug: string
  title: string
  address: string
  hours: string
  latitude?: string
  longitude?: string
  open24_hours: boolean
  restaurant: boolean
  phone: string
  whatsapp: string
  name: string
  features: []
  images: []
}

const UnitsPage = ({
  location,
  pageContext = {},
  slug,
}: PageProps & { slug: string }) => {
  const context = useApi(
    pageContext,
    `stations/${(pageContext as PageContext).slug || slug}`
  ) as PageContext

  if (!context) navigate('/404/')

  const {
    id,
    image,
    title,
    address,
    hours,
    latitude,
    longitude,
    restaurant,
    open24_hours,
    phone,
    whatsapp,
    name,
    features,
    images,
  } = context

  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [photos, setPhotos] = useState<Array<string>>()
  const [index, setIndex] = useState(0)

  const handleSlideChange = (index) => {
    setCurrentSlide(index)
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1699,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <Head location={location} />
      <Nav location={location} />
      <main className={st.core}>
        <div className={cn(st.content1364)}>
          <h1>{title}</h1>
          <h2>{name}</h2>
          <Link to="/">Voltar</Link>
          <div className={st.flex}>
            <section>
              <div className={st.containerSlider}>
                <img src={image} alt="" />
                <div className={st.flags}>
                  {open24_hours === true && <span>Aberto 24h</span>}
                  {restaurant === true && (
                    <span className={st.restaurant}>Restaurante</span>
                  )}
                </div>
                <div className={st.sliderText}>
                  <div>
                    <h3>{address}</h3>
                    <p>
                      Horário de funcionamento: <span>{hours}</span>
                    </p>
                    <p>
                      Telefone: <span>{phone}</span>
                    </p>
                  </div>
                  <ButtonRoutes
                    href={`https://maps.google.com/?q=${address}`}
                  />
                </div>
                {features !== undefined && (
                  <ul className={features.length === 0 && st.displayNone}>
                    {features.map((item, index) => (
                      <li key={index}>{item.name}</li>
                    ))}
                  </ul>
                )}
                {images !== undefined && (
                  <div
                    className={cn(
                      st.slickContainer,
                      images.length === 0 && st.displayNone
                    )}
                  >
                    <Slider
                      {...settings}
                      className={cn(
                        'slick-units-page',
                        images.length === 1 && 'slick-custom'
                      )}
                      ref={slider}
                    >
                      {images.map((item, index) => (
                        <div className={st.cardWrapper} key={index}>
                          <div
                            className={st.cardImage}
                            onClick={() => {
                              setIndex(index)
                              setPhotos(images.map((i) => i.large || i.url))
                            }}
                          >
                            <img src={item.url} alt={item.legend} />
                          </div>
                        </div>
                      ))}
                    </Slider>
                    <div
                      className={cn(
                        st.controls,
                        images.length < 2 && st.displayNone
                      )}
                    >
                      <button
                        onClick={() => {
                          slider?.current?.slickPrev()
                          handleSlideChange(currentSlide - 1)
                        }}
                      ></button>
                      <button
                        onClick={() => {
                          slider?.current?.slickNext()
                          handleSlideChange(currentSlide + 1)
                        }}
                      ></button>
                    </div>
                    <div className={st.cardMobile}>
                      {images.map((item, index) => (
                        <div className={st.cardWrapper} key={index}>
                          <div
                            className={st.cardImage}
                            onClick={() => {
                              setIndex(index)
                              setPhotos(images.map((i) => i.large || i.url))
                            }}
                          >
                            <img src={item.url} alt={item.legend} />
                          </div>
                        </div>
                      ))}
                    </div>
                    <ReactLightBox
                      index={index}
                      images={photos}
                      setIndex={setIndex}
                      setImages={setPhotos}
                    />
                  </div>
                )}
              </div>
            </section>
            <div>
              <section className={st.formYellow}>
                <h2>Envie uma mensagem</h2>
                <p>
                  Utilize o formulário para obter mais informações ou deixar um
                  recado.
                </p>
                <Form id={id} />
              </section>
              <div className={st.phones}>
                {phone && (
                  <div>
                    <a href={`tel:+55${phone.replace(/[^0-9]/g, '')}`}>
                      Ligue: <span>{phone}</span>
                    </a>
                  </div>
                )}
                {whatsapp && (
                  <div>
                    <a
                      href={`https://wa.me/55${whatsapp.replace(
                        /[^0-9]/g,
                        ''
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={whats}
                        alt="Icone do WhatsApp"
                        width={28}
                        height={28}
                      />
                      <span>Chamar no WhatsApp</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

const UnitsRouter = (props) => (
  <Router>
    <UnitsPage path="/unidades/:slug" {...props} />
  </Router>
)

export default UnitsRouter
